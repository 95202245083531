import React from 'react'
import { Link, graphql } from 'gatsby'
import get from 'lodash/get'
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer'
import { BLOCKS } from '@contentful/rich-text-types'
import { GatsbyImage, getImage, StaticImage } from 'gatsby-plugin-image'

import Seo from '../components/seo'
import Container from '../components/container'
import SmallReceipeBanner from '../components/small-receipe-banner'
import FindusBanner from '../components/find-us-banner'
import * as styles from './product-post.module.css'

class ProductTemplate extends React.Component {
  render() {
    const product = get(this.props, 'data.contentfulProduct')
    const previous = get(this.props, 'data.previous')
    const next = get(this.props, 'data.next')
    const plainTextDescription = documentToPlainTextString(
      JSON.parse(product.description.raw)
    )

    const options = {
      renderNode: {
        [BLOCKS.EMBEDDED_ASSET]: (node) => {
          const { gatsbyImage, description } = node.data.target
          return (
            <GatsbyImage
              image={getImage(gatsbyImage)}
              alt={description}
            />
          )
        },
      },
    };

    return (
      <>
        <Seo
          title={product.title}
          description={plainTextDescription}
          image={`http:${product.heroImage.resize.src}`}
        />
        <div className={styles.hero}>
          <GatsbyImage
            image={product.heroImage.gatsbyImage}
            alt={product.title}
            objectFit='cover'
            resive='contain'
            layout='fixed'
            className={styles.heroImage}
          />
          <h1 style={{color: product.primaryColor || ''}}>{product.title}</h1>
          {(previous || next) && (
              <nav>
                <ul className={styles.productNavigation}>
                  {previous && (
                    <li>
                      <Link to={`/produits/${previous.slug}`} rel="prev">
                        ←
                      </Link>
                    </li>
                  )}
                  {next && (
                    <li>
                      <Link to={`/produits/${next.slug}`} rel="next">
                        →
                      </Link>
                    </li>
                  )}
                </ul>
              </nav>
            )}
        </div>

        <div style={{backgroundColor: product.primaryColor || ''}}>
          <Container className={styles.productDetails}>
            <div className={styles.productDescription}>
              {product.body?.raw && renderRichText(product.body, options)}
              <hr />
              <p>
                <span>{product?.weight && product?.weight} gr</span>
              </p>
              <StaticImage
                className={styles.logoAlimentsDuQuebec}
                src="../../static/images/AlimentsDuQuebec_Logo.svg"
                alt="Logo Aliments du Québec"
                placeholder="blurred"
              />
            </div>
            <div className={styles.ingredients}>
              <hr />
              <div>
                <h3>Ingrédients</h3>
                {product.ingredients?.raw && renderRichText(product.ingredients, options)}
              </div>
              <hr />
              <div>
                <h3>Valeur nutritive</h3>
                {product.nutritionalValues?.raw && renderRichText(product.nutritionalValues, options)}
              </div>
              <hr />
            </div>
          </Container>
        </div>
        <FindusBanner />
        <SmallReceipeBanner />
      </>
    )
  }
}

export default ProductTemplate

export const pageQuery = graphql`
  query ProductBySlug(
    $slug: String!
    $previousProductSlug: String
    $nextProductSlug: String
  ) {
    contentfulProduct(slug: { eq: $slug }) {
      slug
      title
      weight
      heroImage {
        gatsbyImage(layout: FULL_WIDTH, placeholder: BLURRED, width: 1280)
        resize(height: 800, width: 1200) {
          src
        }
      }
      primaryColor
      price
      nutritionalValues {
        raw
      }
      body {
        raw
      }
      description {
        raw
      }
      ingredients {
        raw
      }
    }
    previous: contentfulProduct(slug: { eq: $previousProductSlug }) {
      slug
      title
    }
    next: contentfulProduct(slug: { eq: $nextProductSlug }) {
      slug
      title
    }
  }
`
