import React from 'react'
import { Link } from 'gatsby'

import Container from './container'
import * as styles from './small-receipe-banner.module.css'

const SmallReceipeBanner = () => {
  return (
    <div className={styles.smallReceipeBanner}>
      <Container className={styles.smallReceipeBannerContainer}>
        <div>
          <h2>Du Tofutofu<br/> à tout moment </h2>
          <Link to='/recettes' className="button green">Voir les recettes</Link>
        </div>
      </Container>
    </div>
  )
}

export default SmallReceipeBanner
